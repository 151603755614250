/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class EmptyState extends Component {
  get isLiveState() {
    return this.args.state === 'live';
  }

  @action
  switchToDraftActionTab() {
    this.args.changeActionStateTab('draft');
  }
}
