/* import __COLOCATED_TEMPLATE__ from './follow-up-messages-editor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';
import type BotPart from 'embercom/models/operator/bot-intro/part';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    messages: BotPart[];
    addPart: () => void;
    removePart: (part: BotPart) => void;
    reorderParts: (parts: BotPart[]) => void;
    csatEnabled: boolean;
    isReadOnly: boolean;
  };
}

const FollowUpMessagesEditor = templateOnlyComponent<Signature>();
export default FollowUpMessagesEditor;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::FollowUpMessagesEditor': typeof FollowUpMessagesEditor;
  }
}
