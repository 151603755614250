/* import __COLOCATED_TEMPLATE__ from './default.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';

// @ts-ignore
import PredicateGroup from 'predicates/models/predicate-group';
import { type AttributePredicate } from '@intercom/interblocks.ts';
import {
  type PredicateOption,
  type TriggerPredicates,
} from 'embercom/objects/operator/configuration/custom-bot';
import type CustomBotConfig from 'embercom/objects/operator/configuration/custom-bot';

import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';

import AudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';

import StandardScheduleHeaderConfiguration from 'embercom/objects/content-editor/panels/schedule/standard-schedule-header-configuration';

import StandardGoalHeaderConfiguration from 'embercom/objects/content-editor/panels/goal/standard-goal-header-configuration';

import ElementSelectorHeaderConfiguration from 'embercom/objects/content-editor/panels/element-selector/custom-bot/standard-panel-header-configuration';

import {
  ADMIN_NOTE_CREATED_TARGET,
  INTERCOM_CHANNELS,
  CONVERSATION_STARTED_TARGET,
  TRIGGERABLE_BOT_TYPE,
  BUTTON_BOT_TYPE,
  INBOUND_BOT_TYPE,
  OUTBOUND_BOT_TYPE,
  TICKET_CREATED_TARGET,
  TICKET_STATE_UPDATED_TARGET,
} from 'embercom/lib/operator/custom-bots/constants';
import { timezoneTypes } from 'embercom/models/data/matching-system/matching-constants';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import { modifier } from 'ember-modifier';
import { isEmpty, isPresent } from '@ember/utils';
import { schedule } from '@ember/runloop';

interface Args {
  infoPanelTriggerData: CustomBotConfig;
  editorConfiguration: EditorConfig;
  readOnly: boolean;
}

export default class TriggerInfoNodeContent extends Component<Args> {
  @service declare appService: any;
  @service attributeService: any;
  @service declare contentEditorService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service intercomEventService: any;
  @tracked openSectionId?: string = this.initialOpenSectionId;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    if (
      this.triggerPredicates &&
      this.triggerPredicates.options.firstObject &&
      !this.customBot.triggerPredicateGroup
    ) {
      this.customBot.triggerPredicateGroup = this.store.createFragment(
        'predicates/predicate-group',
        {
          predicates: PredicateGroup.convertRawPredicates(this.store, [
            {
              type: this.triggerPredicates.type,
              predicates: [this.triggerPredicates.options.firstObject.predicate],
            },
          ]),
        },
      );
    }
  }

  @computed(
    'app.{canUseFinExperience,canUseFinUsageLimitPredicate}',
    'args.editorConfiguration.{showAudiencePreview,supportsConversationChannelTargeting}',
    'args.infoPanelTriggerData.botType',
    'attributeService.{capacityAttributes,finUsageAttributes,visualBotBuilderConditionalAttributes}',
    'container',
    'customBot.{allowedUserRoles,canUseOmnichannel,isPhoneCallTrigger}',
    'shouldUseEditorConfigBasedTargeting',
    'showEventRules',
  )
  get audienceEditorConfiguration(): StandardAudienceEditorConfiguration {
    if (this.shouldUseEditorConfigBasedTargeting) {
      let audienceEditorConfig: any = new StandardAudienceEditorConfiguration({
        showMatchBehavior: false,
        allowedUserRoles: this.customBot.allowedUserRoles,
        showClientPredicates: false,
        showAudiencePreview: this.args.editorConfiguration.showAudiencePreview,
        audiencePreviewText: this.intl.t(
          'operator.workflows.visual-builder.trigger-info-node-content.audience-preview-text-for-triggerable-bots',
        ),
        ignoredAttributeTypesForPreview: [
          'message',
          'conversation',
          'office_hours',
          'team_capacity',
          'ai_agent',
        ],
        additionalAttributes: this.attributeService.visualBotBuilderConditionalAttributes,
        container: this.container,
        editorConfiguration: this.args.editorConfiguration,
      });

      if (this.args.editorConfiguration.supportsConversationChannelTargeting) {
        audienceEditorConfig.header = ComponentConfiguration.create({
          component: 'content-editor/panels/custom/triggerable-custom-bot/channel-targeting/body',
          config: {},
        } as any);
      }

      audienceEditorConfig.audienceRulesEditor = ComponentConfiguration.create({
        component: 'content-editor/audience-wrappers/content/workflows/audience-rules-editor',
        config: audienceEditorConfig.inputs,
      } as any);

      return audienceEditorConfig;
    } else if (this.args.infoPanelTriggerData.botType === TRIGGERABLE_BOT_TYPE) {
      let audienceEditorConfig: any = new StandardAudienceEditorConfiguration({
        showMatchBehavior: false,
        allowedUserRoles: this.customBot.allowedUserRoles,
        showClientPredicates: false,
        showAudiencePreview: true,
        audiencePreviewText: this.intl.t(
          'operator.workflows.visual-builder.trigger-info-node-content.audience-preview-text-for-triggerable-bots',
        ),
        ignoredAttributeTypesForPreview: [
          'message',
          'conversation',
          'office_hours',
          'team_capacity',
          'ai_agent',
        ],
        additionalAttributes: this.attributeService.visualBotBuilderConditionalAttributes,
        includeTicketAttributeGroups: true,
        container: this.container,
      });

      if (this.customBot.canUseOmnichannel && !this.customBot.isPhoneCallTrigger) {
        audienceEditorConfig.header = ComponentConfiguration.create({
          component: 'content-editor/panels/custom/triggerable-custom-bot/channel-targeting/body',
          config: {},
        } as any);
        audienceEditorConfig.audienceRulesEditor = ComponentConfiguration.create({
          component:
            'content-editor/audience-wrappers/content/triggerable-custom-bots/audience-rules-editor',
          config: audienceEditorConfig.inputs,
        } as any);
      }

      return audienceEditorConfig;
    } else {
      let ignoredAttributeTypesForPreview = [];
      let additionalAttributes = [];

      ignoredAttributeTypesForPreview.push('team_capacity');
      additionalAttributes.push(...this.attributeService.capacityAttributes);

      if (this.app?.canUseFinUsageLimitPredicate && this.app?.canUseFinExperience) {
        ignoredAttributeTypesForPreview.push('ai_agent');
        additionalAttributes.push(...this.attributeService.finUsageAttributes);
      }

      return new StandardAudienceEditorConfiguration({
        showMatchBehavior: false,
        showEventRules: this.showEventRules,
        showDateEvents: false,
        allowedUserRoles: this.customBot.allowedUserRoles,
        container: this.container,
        additionalAttributes,
        ignoredAttributeTypesForPreview,
      });
    }
  }

  get shouldUseEditorConfigBasedTargeting() {
    let featureFlagEnabled = this.app.canUseWorkflowsTicketsV3;
    let eligibleTarget = [
      TICKET_CREATED_TARGET,
      TICKET_STATE_UPDATED_TARGET,
      ADMIN_NOTE_CREATED_TARGET,
    ].includes(this.args.infoPanelTriggerData.target);
    return featureFlagEnabled && eligibleTarget;
  }

  get triggerNameWhenEditModeActive() {
    if (this.args.readOnly) {
      return false;
    }
    return this.args.infoPanelTriggerData.target;
  }

  get initialOpenSectionId() {
    return this.args.readOnly ? undefined : 'rules';
  }

  get app() {
    return this.appService.app;
  }

  get container() {
    return getOwner(this);
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get customBot() {
    return this.contentEditorService.activeObject;
  }

  get hasConversationStartedTrigger() {
    return this.args.infoPanelTriggerData.target === CONVERSATION_STARTED_TARGET;
  }

  get showEventRules() {
    if (
      this.args.infoPanelTriggerData.botType === BUTTON_BOT_TYPE ||
      this.args.infoPanelTriggerData.botType === INBOUND_BOT_TYPE
    ) {
      return false;
    } else if (this.args.infoPanelTriggerData.botType === OUTBOUND_BOT_TYPE) {
      return true;
    }
    return false;
  }

  get showFrequencyEditor() {
    if (this.args.infoPanelTriggerData.botType === OUTBOUND_BOT_TYPE) {
      return true;
    }
    return false;
  }

  get triggerPredicates(): TriggerPredicates | null {
    return this.args.infoPanelTriggerData.triggerPredicates;
  }

  get selectedTriggerPredicates(): string[] {
    return this.customBot.triggerPredicateGroup.predicates.firstObject?.predicates.map(
      (o: AttributePredicate) => o.value,
    );
  }

  get selectedTriggerPredicatesWithFullContext(): PredicateOption[] {
    return this.triggerPredicates
      ? this.triggerPredicates.options.filter((option) =>
          this.selectedTriggerPredicates.includes(option.value),
        )
      : [];
  }

  get triggerPredicatesDescriptionTranslationKey(): string | null {
    return this.args.infoPanelTriggerData.triggerPredicatesDescriptionTranslationKey;
  }

  get hasMultiplePredicatesSelected(): boolean {
    return this.selectedTriggerPredicates?.length > 1;
  }

  get audienceHeaderConfig() {
    let showChannelTargetingSummary;
    let showUserTargetingSummary;

    if (this.shouldUseEditorConfigBasedTargeting) {
      showChannelTargetingSummary =
        this.args.editorConfiguration.supportsConversationChannelTargeting;
      showUserTargetingSummary = this.args.editorConfiguration.supportsUserTargeting;
    } else {
      showChannelTargetingSummary =
        this.customBot.canUseOmnichannel && !this.customBot.isPhoneCallTrigger;
      showUserTargetingSummary = true;
    }

    return AudienceHeaderConfiguration.create(
      {
        title: this.customBot.isPhoneCallTrigger
          ? this.intl.t(
              'operator.workflows.visual-builder.trigger-info-node-content.phone-bot-audience-header-title',
            )
          : this.intl.t(
              'operator.workflows.visual-builder.trigger-info-node-content.audience-header-title',
            ),
        hideMatchBehavior: true,
        showChannelTargetingSummary,
        showUserTargetingSummary,
      } as never,
      { container: this.container } as never,
    );
  }

  get scheduleHeaderConfig() {
    return StandardScheduleHeaderConfiguration.create(
      {
        title: this.intl.t(
          'operator.workflows.visual-builder.trigger-info-node-content.schedule-header-title',
        ),
      } as never,
      { container: this.container } as never,
    );
  }

  get scheduleEditorConfiguration() {
    return {
      showFrequencyEditor: this.showFrequencyEditor,
      timezoneType: timezoneTypes.basedOnAppTimezone,
      scheduleEditorConfiguration: this.args.infoPanelTriggerData.botType === BUTTON_BOT_TYPE && {
        banner: ComponentConfiguration.create({
          component: 'content-editor/panels/schedule/content/button-custom-bot/banner-component',
          content: this.intl.t('operator.custom-bot.editor.schedule-panel.banner'),
        } as any),
      },
    };
  }

  get goalHeaderConfig() {
    return StandardGoalHeaderConfiguration.create(
      {
        title: this.intl.t(
          'operator.workflows.visual-builder.trigger-info-node-content.goal-header-title',
        ),
      } as never,
      { container: this.container } as never,
    );
  }

  get goalEditorConfiguration() {
    return {
      introText: this.intl.t('operator.custom-bot.editor.goal-panel.description'),
    };
  }

  get elementSelectorHeaderConfiguration() {
    return ElementSelectorHeaderConfiguration.create(
      {
        title: this.intl.t(
          'operator.workflows.visual-builder.trigger-info-node-content.element-selector-title',
        ),
        fullScreenEnabled: false,
      } as never,
      { container: this.container } as never,
    );
  }

  isLogicalTriggerPredicate(type: string) {
    return ['or', 'and'].includes(type);
  }

  resetTargetChannels = modifier(
    () => {
      if (!this.shouldUseEditorConfigBasedTargeting) {
        return;
      }

      if (
        this.args.editorConfiguration.supportsConversationChannelTargeting &&
        isEmpty(this.customBot.targetChannels)
      ) {
        schedule('afterRender', () => this.customBot.set('targetChannels', INTERCOM_CHANNELS));
      } else if (
        !this.args.editorConfiguration.supportsConversationChannelTargeting &&
        isPresent(this.customBot.targetChannels)
      ) {
        schedule('afterRender', () => this.customBot.set('targetChannels', []));
      }
    },
    { eager: false },
  );

  @action onOpenSectionChange(sectionId: string) {
    if (sectionId !== null) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'visual_builder_trigger_info_sidesheet_panel',
        action: 'opened',
        panel: sectionId,
      });
    }

    this.openSectionId = sectionId;
  }

  @action
  toggleTriggerPredicateOption(value: string) {
    // at least one predicate must be selected
    if (
      this.selectedTriggerPredicates.includes(value) &&
      this.selectedTriggerPredicates.length === 1
    ) {
      return;
    }

    if (!this.triggerPredicates) {
      return;
    }

    let triggerPredicates = this.triggerPredicates;

    if (this.isLogicalTriggerPredicate(triggerPredicates.type)) {
      let predicates = [
        ...this.selectedTriggerPredicates.filter((v) => v !== value),
        ...(!this.selectedTriggerPredicates.includes(value) ? [value] : []),
      ].reduce((predicates, value) => {
        let perdicateOption = triggerPredicates.options.find((option) => option.value === value);
        if (!perdicateOption) {
          return predicates;
        }

        return [...predicates, perdicateOption.predicate];
      }, [] as AttributePredicate[]);

      if (!predicates.length) {
        return;
      }

      this.customBot.triggerPredicateGroup = this.store.createFragment(
        'predicates/predicate-group',
        {
          predicates: PredicateGroup.convertRawPredicates(this.store, [
            { type: triggerPredicates.type, predicates },
          ]),
        },
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNodeContent::Default': typeof TriggerInfoNodeContent;
    'workflows/graph-editor/trigger-info-node-content/default': typeof TriggerInfoNodeContent;
  }
}
