/* import __COLOCATED_TEMPLATE__ from './template-action-preview-modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import type RouterService from '@ember/routing/router-service';
import { tracked } from 'tracked-built-ins';
import { capitalize } from '@ember/string';

const LIMIT_RESPONSE_FIELDS = 10;

interface Args {
  onModalClose: () => void;
  selectedActionId: string;
}

export default class TemplateActionPreviewModal extends Component<Args> {
  @service declare store: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare notificationsService: $TSFixMe;
  @service attributeService: $TSFixMe;
  @service declare contentImportService: $TSFixMe;

  @tracked user = null;

  @tracked declare workflowAction: $TSFixMe;
  @tracked messengerLang = this.app.locale;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchAiContentAndContentIngestionState).perform();
    taskFor(this.fetchWorkflowAction).perform();
  }

  @dropTask
  *fetchAiContentAndContentIngestionState() {
    yield Promise.all([
      this.contentImportService.fetchAiContentLibrarySummary(),
      this.contentImportService.fetchContentIngestionState(),
    ]);
  }

  @dropTask
  *fetchWorkflowAction(): TaskGenerator<void> {
    try {
      this.workflowAction = yield this.store.findRecord(
        'workflow-connector/action',
        this.args.selectedActionId,
      );
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.actions.templates.fetching-error'),
      );
    }
  }

  get ruleset() {
    return this.workflowAction.ruleset;
  }

  get predicatesForAudiencePreview() {
    return this.ruleset.predicateGroup.predicates.map((predicate: any) => {
      return capitalize(predicate.descriptionWithName);
    });
  }

  get userUnprotectedFromSpoofing() {
    return !this.workflowAction.userProtectedFromSpoofing;
  }

  @action
  setMessengerLang(newLang: string) {
    this.messengerLang = newLang;
  }

  @action
  async createDraftAction(section: string) {
    await taskFor(this.createActionFromTemplate)
      .perform('draft')
      .then((createdAction: any) => {
        this.router.transitionTo(
          'apps.app.settings.app-settings.custom-actions.custom-action',
          createdAction.id,
          { queryParams: { section } },
        );
        this.args.onModalClose();
      });
  }

  @action
  async createLiveAction() {
    await taskFor(this.createActionFromTemplate)
      .perform('live')
      .then(() => {
        this.notificationsService.notifyConfirmation(
          this.intl.t('workflow-connector.actions.templates.created-live-action-success', {
            actionName: this.workflowAction.name,
          }),
        );
        this.args.onModalClose();
      });
  }

  get app() {
    return this.appService.app;
  }

  @dropTask
  *createActionFromTemplate(state: string): TaskGenerator<void> {
    try {
      let params = {
        app_id: this.app.id,
        template_action_id: this.args.selectedActionId,
        state,
      };
      let createdAction = yield post(
        `/ember/workflow_connector/actions/create_action_from_template`,
        params,
      );
      this.trackAnalyticsEvent(`created-${state}-from-template`, 'custom-action', createdAction.id);
      return createdAction;
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.actions.templates.error'),
      );
    }
  }

  trackAnalyticsEvent(action: string, object: string, actionId = null) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      actionId,
    });
  }

  @action
  sendSuggestedQuestionToFinPreview(message: string) {
    let finPreview: any = window.document.querySelector('#hosted-messenger-fin-demo-preview');
    finPreview?.contentWindow?.postMessage(
      JSON.stringify({ type: 'intercom-send-message', message }),
      '*',
    );
    this.trackAnalyticsEvent('send-suggested-question-to-fin', 'custom-action');
  }

  get templateSuggestedQuestions() {
    return this.workflowAction.suggestedQuestions;
  }

  get isLoading() {
    return (
      taskFor(this.fetchWorkflowAction).isRunning ||
      taskFor(this.createActionFromTemplate).isRunning ||
      !this.finResponseFields.length
    );
  }

  get audienceAttributes() {
    return this.workflowAction.ruleset;
  }

  get finResponseFields() {
    return this.workflowAction.responseFields.filterBy('redacted', false);
  }

  get finResponseFieldsLimited() {
    return this.finResponseFields.slice(0, LIMIT_RESPONSE_FIELDS);
  }

  get finResponseFieldsRestNumber() {
    return this.finResponseFields.length - LIMIT_RESPONSE_FIELDS;
  }

  get finResponseFieldsRestTooltip() {
    return this.finResponseFields
      .slice(LIMIT_RESPONSE_FIELDS)
      .map((field: any) => field.displayablePath);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::TemplateActionPreviewModal': typeof TemplateActionPreviewModal;
    'workflow-connector/template-action-preview-modal': typeof TemplateActionPreviewModal;
  }
}
