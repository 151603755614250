/* import __COLOCATED_TEMPLATE__ from './trigger-workflow.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import {
  matchBehaviors,
  objectTypes,
  states,
  permissionsMap,
} from 'embercom/models/data/matching-system/matching-constants';
import { NO_TRIGGER_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import Component from '@glimmer/component';
import Ruleset from 'embercom/models/matching-system/ruleset';
import { TRIGGERABLE_BOT_TYPE } from 'embercom/lib/operator/custom-bots/constants';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { intersection } from 'underscore';

export default class TriggerWorkflow extends Component {
  @service appService;
  @service intl;
  @service notificationsService;
  @service outboundHomeService;
  @service permissionsService;
  @service contentEditorService;
  @service router;
  @service store;

  @tracked workflows = [];
  @tracked selectorIsOpen = false;
  @tracked showBotCreationModal = false;

  noTriggerObjectType = objectTypes.triggerableCustomBot;
  noTriggerTarget = NO_TRIGGER_TARGET;
  permissionKey = permissionsMap[objectTypes.triggerableCustomBot];

  constructor() {
    super(...arguments);

    if (this.isOmnichannelWorkflow) {
      this.fetchTargetChannels.perform();
    }
  }

  @task({ drop: true })
  *fetchTargetChannels() {
    let activeObject = this.contentEditorService.activeObject;
    if (this.isOmnichannelCustomBot && activeObject.isNoTrigger) {
      let rootWorkflows = yield activeObject.loadRootWorkflows();
      if (rootWorkflows.length > 0) {
        this.targetChannels = intersection(...rootWorkflows.mapBy('targetChannels'));
      } else {
        // if there are no root bots, then every LB can be selected
        this.targetChannels = null;
      }
    } else {
      this.targetChannels = activeObject.targetChannels;
    }
  }

  @task({ drop: true })
  *fetchNoTriggerBots(workflowIds = [], includeCompatibleChannels = false) {
    return yield ajax({
      url: `/ember/operator_custom_bots/${this.args.editorState.workflowInstanceEntityId}/no_trigger_bots`,
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
        workflow_ids: workflowIds,
        include_compatible_channels: includeCompatibleChannels,
      },
    });
  }

  @task({ drop: true })
  *fetchTriggerableWorkflows() {
    let triggerableWorkflows = [];
    let noTriggerBots = yield this.fetchNoTriggerBots.perform();

    noTriggerBots.forEach((bot) => {
      triggerableWorkflows.push({
        text:
          bot.title || this.intl.t('operator.custom-bot.editor.no-trigger-bot-selector.untitled'),
        value: bot.operator_workflow_instance_id,
        icon: 'bot-filled',
        botId: bot.id,
        state: bot.state,
        component: 'operator-flows/editor/trigger-workflow/no-trigger-bot-option',
        compatibleChannels: bot.compatible_channels,
        userVisible: bot.user_visible,
        componentAttrs: {
          showTooltips:
            (this.isOmnichannelWorkflow &&
              !this.isCompatible(this.targetChannels, bot.compatible_channels)) ||
            bot.state !== states.live,
          tooltipText: this.getNoTriggerBotTooltipText(bot),
        },
        live: bot.state === states.live,
        isDisabled:
          (this.isOmnichannelWorkflow &&
            !this.isCompatible(this.targetChannels, bot.compatible_channels)) ||
          bot.state !== states.live,
      });
    });

    // sort by bot state in the order live, paused, draft
    if (this.isOmnichannelWorkflow) {
      let compatibleWorkflows = triggerableWorkflows.filter(
        (bot) =>
          bot.state === states.live &&
          this.isCompatible(this.targetChannels, bot.compatibleChannels),
      );
      let incompatibleWorkflows = triggerableWorkflows.filter(
        (bot) =>
          !this.isCompatible(this.targetChannels, bot.compatibleChannels) ||
          bot.state === states.paused ||
          bot.state === states.draft,
      );
      this.workflows = [...compatibleWorkflows, ...incompatibleWorkflows];
    } else {
      let liveWorkflows = triggerableWorkflows.filter((bot) => bot.state === states.live);
      let pausedWorkflows = triggerableWorkflows.filter((bot) => bot.state === states.paused);
      let draftWorkflows = triggerableWorkflows.filter((bot) => bot.state === states.draft);
      this.workflows = [...liveWorkflows, ...pausedWorkflows, ...draftWorkflows];
    }

    // If the step has a workflow id that doesn't exist in the list of possible triggerable workflows,
    // ensure that no workflow is selected in the select component.
    // (This is defensive as embercom will crash if there is a selected value that isn't an option in our
    // select component).
    let possibleWorkflowIds = this.workflows.map((wf) => wf.value);
    if (!possibleWorkflowIds.includes(this.args.step.triggerableWorkflowId)) {
      this.setTriggerableWorkflowId(undefined);
    }
  }

  isCompatible(targetChannels, compatibleChannels) {
    if (!targetChannels || !compatibleChannels) {
      return true;
    }

    return intersection(targetChannels, compatibleChannels).length === targetChannels.length;
  }

  getNoTriggerBotTooltipText(bot) {
    if (bot.state !== states.live) {
      return this.intl.t('operator.custom-bot.editor.no-trigger-bot-selector.require-set-bot-live');
    } else if (
      this.isOmnichannelWorkflow &&
      !this.isCompatible(this.targetChannels, bot.compatible_channels)
    ) {
      return this.intl.t(
        'operator.custom-bot.editor.no-trigger-bot-selector.contains-incompatible-steps',
        {
          channels: this.intl.formatList(
            this.targetChannels.filter((channel) => !bot.compatible_channels.includes(channel)),
            {
              style: 'long',
              type: 'conjunction',
            },
          ),
        },
      );
    }
  }

  get isOmnichannelWorkflow() {
    return (
      this.contentEditorService.activeRulesetLink &&
      (this.isOmnichannelCustomBot || this.isOmnichannelResolutionBot)
    );
  }

  get isOmnichannelCustomBot() {
    return (
      this.contentEditorService.activeRulesetLink.objectType === objectTypes.triggerableCustomBot
    );
  }

  get isOmnichannelResolutionBot() {
    return (
      this.contentEditorService.activeRulesetLink.objectType === objectTypes.resolutionBotBehavior
    );
  }

  get hasWorkflows() {
    return this.workflows.length > 0;
  }

  get items() {
    if (this.loading) {
      return [
        {
          value: null,
          component: 'operator-flows/editor/trigger-workflow/loading-state',
          componentShouldReplaceItem: true,
        },
      ];
    }
    if (!this.hasWorkflows) {
      return [
        {
          value: null,
          component: 'operator-flows/editor/trigger-workflow/empty-state',
          componentShouldReplaceItem: true,
        },
      ];
    }
    return [
      {
        items: this.workflows,
      },
    ];
  }

  get loading() {
    return this.fetchTriggerableWorkflows.isRunning;
  }

  get selectedValue() {
    return !this.loading && this.hasWorkflows ? this.args.step.triggerableWorkflowId : undefined;
  }

  get selectedWorkflow() {
    if (this.selectedValue) {
      return this.workflows.find((wf) => wf.value === this.selectedValue);
    }

    return undefined;
  }

  get hasError() {
    return (
      (!this.loading && !this.args.step.triggerableWorkflowId) ||
      !this.args.step.validations.isValid
    );
  }

  @action
  async createCustomBot() {
    if (!this.canCreateCustomBot()) {
      await this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(this.permissionKey);
      this.toggleBotCreationModal();
      return;
    }

    let params = {
      app_id: this.appService.app.id,
      object_type: objectTypes.triggerableCustomBot,
      match_behavior: matchBehaviors.transient,
      object_data: { type: TRIGGERABLE_BOT_TYPE },
    };

    try {
      let ruleset = await Ruleset.createForType(this.store, params);
      safeWindowOpen(
        this.router.urlFor('apps.app.automation.workflows.edit', ruleset.id, {
          queryParams: { mode: 'edit' },
        }),
      );
      this.showBotCreationModal = false;
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'operator.custom-bot.editor.no-trigger-bot-selector.could-not-create-bot',
        ),
      });
    }
  }

  @action
  toggleBotCreationModal() {
    this.showBotCreationModal = !this.showBotCreationModal;
  }

  canCreateCustomBot() {
    if (isBlank(this.permissionKey)) {
      return true;
    }

    return this.permissionsService.currentAdminCan(this.permissionKey);
  }

  @action
  async setTriggerableWorkflowId(workflowId) {
    this.args.step.triggerableWorkflowId = workflowId;
    if (workflowId) {
      let bots = await this.fetchNoTriggerBots.perform([workflowId], true);
      this.args.step.compatibleChannels = bots[0]?.compatible_channels;
      this.args.step.userVisible = this.workflows.find(
        (wf) => wf.value === workflowId,
      )?.userVisible;
      // Ember has a tracking issue where updating userVisible doesn't update the UI
      this.args.step.group.notifyPropertyChange('isCustomerFacing');
    }
  }

  @action
  onSelectorOpen() {
    this.fetchTriggerableWorkflows.perform();
    this.selectorIsOpen = true;
  }

  @action
  onSelectorClose() {
    this.args.step.justAdded = false;
    this.selectorIsOpen = false;
  }
}
