/* import __COLOCATED_TEMPLATE__ from './graph-side-sheet.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface Args {
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Args: Args;
}

const GraphSideSheet = templateOnlyComponent<Signature>();
export default GraphSideSheet;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphSideSheet': typeof GraphSideSheet;
    'workflows/graph-side-sheet': typeof GraphSideSheet;
  }
}
