/* import __COLOCATED_TEMPLATE__ from './headers.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class Headers extends Component {
  @service store;
  @service router;
  @service intl;

  @tracked isAddingAuthHeader;

  constructor() {
    super(...arguments);
  }

  get isActionClass() {
    return this.args.headerClass === 'workflow-connector/action-header';
  }

  get isAuthClass() {
    return this.args.headerClass === 'custom-authentication/refresh-token-configuration-header';
  }

  get tokenFooterActions() {
    return [
      {
        text: this.intl.t('workflow-connector.builder.body.request.tabs.authentication.new-label'),
        icon: 'new',
        action: () =>
          safeWindowOpen(this.router.urlFor('apps.app.settings.custom-authentication-tokens.new')),
      },
      {
        text: this.intl.t(
          'workflow-connector.builder.body.request.tabs.authentication.manage-tokens',
        ),
        icon: 'settings',
        action: () =>
          safeWindowOpen(this.router.urlFor('apps.app.settings.custom-authentication-tokens')),
      },
    ];
  }

  get addHeaderList() {
    return [
      {
        items: [
          {
            text: this.intl.t('workflow-connector.builder.body.request.key-value'),
            onSelectItem: () => this.addHeader(),
          },
          {
            text: this.intl.t('workflow-connector.builder.body.request.auth-token'),
            onSelectItem: () => this.startAddingAuthHeader(),
          },
        ],
      },
    ];
  }

  get showAuthHeader() {
    return isPresent(this.args.selectedToken) || this.isAddingAuthHeader;
  }

  @action
  addHeader() {
    this.args.headerObject.headers.pushObject(
      this.store.createFragment(this.args.headerClass, {
        name: '',
        value: '',
      }),
    );
  }

  @action
  startAddingAuthHeader() {
    this.isAddingAuthHeader = true;
  }

  @action
  clearSelectedToken() {
    this.isAddingAuthHeader = false;
    this.args.clearSelectedToken();
  }

  @action
  deleteHeader(headerNameValue) {
    this.args.headerObject.headers.removeFragment(headerNameValue);
  }
}
