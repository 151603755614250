/* import __COLOCATED_TEMPLATE__ from './auto-close.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: $TSFixMe;
    step: $TSFixMe;
    isReadOnly: boolean;
    durationInDaysHoursMinutes: string;
    days: number | string | undefined;
    hours: number | string | undefined;
    minutes: number | string | undefined;
    updateInactivityDuration: () => void;
    addPart: () => void;
    removePart: (part: any) => void;
    reorderParts: (parts: any[]) => void;
  };
}

const AutoClose = templateOnlyComponent<Signature>();
export default AutoClose;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Sections::AutoClose': typeof AutoClose;
  }
}
