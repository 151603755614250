/* import __COLOCATED_TEMPLATE__ from './attribute-collector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AttributeCollector extends Component {
  @service attributeService;

  get attribute() {
    return this.attributeService.botAutoMessageCollectableAttributes.find(
      (attribute) => attribute.identifier === this.args.step.attributeIdentifier,
    );
  }
}
