/* import __COLOCATED_TEMPLATE__ from './placeholder-node.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';
import { stepModelClasses, stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import type { StepNames, StepType } from 'embercom/objects/visual-builder/configuration-list';
import type Group from 'embercom/models/operator/visual-builder/group';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import type { BotConfigTarget as Target } from 'embercom/objects/operator/configuration/configuration';
import type InserterMenuConfiguration from 'embercom/objects/visual-builder/configuration/inserter-menu';

const CONTENT_DIRECTORY = 'workflows/graph-editor/placeholder-node-content';
const DEFAULT_CONTENT_COMPONENT = `${CONTENT_DIRECTORY}/default` as const;

interface Signature {
  Args: {
    editorConfiguration: EditorConfig;
    group: Group;
    editorState: EditorState;
    readOnly: boolean;
    placeholderName: Target | string;
  };
}

export default class PlaceholderNode extends Component<Signature> {
  @tracked stepTypes: StepType;

  constructor(owner: any, args: ArgsOf<PlaceholderNode>) {
    super(owner, args);
    this.stepTypes = stepTypes;
  }

  get inserterMenuConfig(): InserterMenuConfiguration {
    return this.args.editorConfiguration.generatePathConfig({
      path: this.args.group,
      editorState: this.args.editorState,
    }).stepInserterMenu;
  }

  @action
  onSelectStep(stepName: StepNames) {
    this.inserterMenuConfig.onSelectItem({
      ModelClass: stepModelClasses[stepName],
      analyticsData: {
        step_menu_location: 'starting_path_placeholder_suggested_step',
      },
    });
  }

  get contentComponent() {
    let contentPath = `${CONTENT_DIRECTORY}/${this.args.placeholderName}`;
    let componentExists = (getOwner(this) as any).hasRegistration(`component:${contentPath}`);

    // A casting hack to make glint understand the component signature of the placeholde content components
    // We know all content components implement the same interface and the fallback is the default component
    return (
      componentExists ? contentPath : DEFAULT_CONTENT_COMPONENT
    ) as typeof DEFAULT_CONTENT_COMPONENT;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::PlaceholderNode': typeof PlaceholderNode;
  }
}
