/* import __COLOCATED_TEMPLATE__ from './mock-response.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import type Action from 'embercom/models/workflow-connector/action';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  workflowAction: Action;
  updateResponseFields: () => void;
  nextSection: (sectionId: string) => void;
}

export default class MockResponse extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;

  workflowMockResponseBody =
    this.args.workflowAction.mockResponse?.body || '{ \n  // Enter in JSON format\n}';

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (!this.args.workflowAction.mockResponse) {
      this.args.workflowAction.mockResponse = this.store.createFragment(
        'workflow-connector/mock-response',
      );
    }
  }

  @action
  updateResponseBody(value: string) {
    this.args.workflowAction.mockResponse.body = value;

    if (this.args.workflowAction.mockIsValid) {
      this.args.updateResponseFields();
    }
  }

  @action
  nextSection() {
    this.args.nextSection('response_section');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Testing::MockResponse': typeof MockResponse;
    'workflow-connector/builder/body/sections/testing/mock-response': typeof MockResponse;
  }
}
