/* import __COLOCATED_TEMPLATE__ from './classify-conversation-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import type { default as ClassifyConversationAttributeStepType } from 'embercom/models/operator/visual-builder/step/classify-conversation-attribute';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export interface Args {
  step: ClassifyConversationAttributeStepType;
  editorState: EditorState;
  onChange: () => void;
  onDelete: () => void;
  readOnly: boolean;
}

export default class ClassifyConversationAttribute extends Component<Args> {
  @service declare appService: any;

  get isClassificationAvailable() {
    return this.appService.app.canUseAiConversationClassification;
  }

  get hasValidationErrors() {
    return this.args.editorState.shouldShowValidations && this.args.step.validations.isInvalid;
  }

  get shouldShowError() {
    return this.hasValidationErrors || !this.isClassificationAvailable;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ClassifyConversationAttribute': typeof ClassifyConversationAttribute;
  }
}
