/* import __COLOCATED_TEMPLATE__ from './standard-body.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Action from 'embercom/models/workflow-connector/action';

interface Args {
  workflowAction: Action;
  section?: string;
}

export default class StandardBody extends Component<Args> {
  @tracked openSectionId = this.args.section || 'general_section';
  @service intercomEventService: any;
  @tracked templatedParameters: any = {};

  constructor(owner: any, args: any) {
    super(owner, args);
    this.requestParameters();
  }

  requestParameters() {
    Array.from(this.args.workflowAction.attributes).map(
      (attribute) =>
        (this.templatedParameters[attribute[1]] = this.getValueforAttribute(attribute[1])),
    );
  }

  getValueforAttribute(attribute: string) {
    return this.args.workflowAction.testResult?.testValues
      ? this.args.workflowAction.testResult.testValues[attribute] || ''
      : '';
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'custom_action_accordion',
      accordian_name: sectionId,
    });
    this.openSectionId = sectionId;
  }

  @action
  saveTemplatedParameter(requestParameter: string, requestValue: any) {
    this.templatedParameters[requestParameter] = requestValue;
  }

  @action
  clearTemplatedParameter() {
    this.templatedParameters = {};
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::StandardBody': typeof StandardBody;
    'workflow-connector/builder/body/standard-body': typeof StandardBody;
  }
}
