/* import __COLOCATED_TEMPLATE__ from './user-table-row-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { action, computed } from '@ember/object';
import { or } from '@ember/object/computed';
import Component from '@ember/component';
import LegacySelectionState from 'embercom/models/legacy-selection-state';
import { inject as service } from '@ember/service';

export default Component.extend({
  ctiService: service(),
  intercomCallService: service(),
  router: service(),
  session: service(),
  appService: service(),
  intercomEventService: service(),
  tagName: 'tr',
  classNames: ['tbl__user-list-row'],
  classNameBindings: [
    'user.selected:shim__checkbox-selected',
    'user.isHighlighted:kbd_item_selected',
  ],
  isCompany: false,
  selectionState: computed('model', function () {
    return LegacySelectionState.create({
      isSelectAllMode: false,
      selected: [this.user],
      unselected: [],
      predicates: [],
      count: 1,
      search: '',
    });
  }),
  shouldHaveVisibleCheckbox: or('isHovered', 'hasSelectedUsers'),
  mouseEnter() {
    this.set('isHovered', true);
  },
  mouseLeave() {
    this.set('isHovered', false);
  },

  get phoneCallEscalationEnabled() {
    return this.user?.phone && this.ctiService.ctiAvailable && !this.ctiService.initiatedCall;
  },

  onCallClick: action(function () {
    if (this.user?.phone) {
      this.ctiService.initiateNewCall(this.user.phone);
    }
  }),

  openDialler: action(async function () {
    this.intercomCallService.openDialler(this.user?.phone);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'dialler',
      place: 'contact_list',
    });
    await this.session.getTeammate(this.appService.app.id);
    this.router.transitionTo('inbox.workspace.inbox', this.appService.app.id);
  }),

  get shouldShowPhoneCallButton() {
    return this.user?.phone && this.intercomCallService.hasActiveOutboundPhoneNumber;
  },
});
