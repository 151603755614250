/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { type ComponentLike } from '@glint/template';

interface Args {
  editorState: EditorState;
  analyticsData: any;
  sideSheetHeaderComponent: ComponentLike;
  sideSheetBodyComponent: ComponentLike;
  forceOpen: boolean;
}

// this component is used to open the side sheet for trigger-info-node rather than it being triggered on click
// we also need to pass components to openSideSheet which can only be done in hbs files not js files
// there is no template to this component due to the above reason, we only needed the constructor to trigger the side sheet logic

export default class DefaultSideSheetOpener extends Component<Args> {
  constructor(owner: unknown, args: any) {
    super(owner, args);
    if (this.args.forceOpen) {
      this.triggerSideSheet();
    }
  }

  @action triggerSideSheet() {
    this.args.editorState.openSideSheet({
      header: this.args.sideSheetHeaderComponent,
      className: 'trigger-info-node__graph__side-sheet',
      body: this.args.sideSheetBodyComponent,
      key: 'trigger-info-node',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNode::DefaultSideSheetOpener': typeof DefaultSideSheetOpener;
    'workflows/graph-editor/trigger-info-node/default-side-sheet-opener': typeof DefaultSideSheetOpener;
  }
}
