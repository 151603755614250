/* import __COLOCATED_TEMPLATE__ from './testing.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import type Action from 'embercom/models/workflow-connector/action';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

interface Args {
  workflowAction: Action;
  templatedParameters: any;
  clearTemplatedParameter: () => void;
  nextSection: (sectionId: string) => void;
  saveTemplatedParameter: (requestParameter: any, requestValue: any) => void;
}
export default class Testing extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: any;
  @service declare notificationsService: any;

  get isMockConfigurationSelected() {
    return this.args.workflowAction?.configurationResponseType === 'mock_response_type';
  }

  @action
  onSelectConfigurationResponseTypeChange(selectedConfigurationResponseType: string) {
    if (this.args.workflowAction.configurationResponseType === selectedConfigurationResponseType) {
      return;
    }

    this.args.workflowAction.configurationResponseType = selectedConfigurationResponseType;
    this.updateResponseFields();
  }

  @action
  updateResponseFields() {
    if (
      (this.args.workflowAction.isMockResponse && this.args.workflowAction.mockIsValid) ||
      (this.args.workflowAction.isTestResponse && this.args.workflowAction.testIsValid)
    ) {
      taskFor(this.getResponseFields).perform();
    } else {
      this.args.workflowAction.responseFields = [];
    }
  }

  @action
  nextSection() {
    this.args.nextSection('response_section');
  }

  @task({ drop: true })
  *getResponseFields(): TaskGenerator<void> {
    try {
      let response = yield post(
        `/ember/workflow_connector/actions/${this.args.workflowAction.id}/response_fields`,
        {
          app_id: this.appService.app.id,
          workflow_action: this.args.workflowAction,
        },
      );

      let responseFields = response.response_fields.map((responseField: any) => {
        return this.store.createFragment(
          'workflow-connector/response-field',
          (this.store.normalize('workflow-connector/response-field', responseField) as any).data
            .attributes,
        );
      });

      this.args.workflowAction.responseFields = responseFields;
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.builder.body.testing.error-message'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Testing': typeof Testing;
    'workflow-connector/builder/body/sections/testing': typeof Testing;
  }
}
